// This file contains variables for different themes

export const light = {
	body: "#fff",
	text: "#202020", // black shade
	bodyRgba: "255, 255, 255",
	textRgba: "32,32,32",
	red: "#da1333",
	blue: "#213875",
	lightblue: "#4f67a6",
	lightred: "#ff4f5a",
	black: "#000",

	carouselColor: "#EEEDDE",

	fontxs: "0.75em",
	fontsm: "0.875em",
	fontmd: "1em", // 1em = 16px
	fontlg: "1.25em",
	fontxl: "2em",
	fontxxl: "3em",
	fontxxxl: "4em",

	fontButton: "0.875em",

	navHeight: "5rem",
};

export const dark = {
	body: "#fff",
	text: "#fff", // black shade
	bodyRgba: "32,32,32",
	textRgba: "255, 255, 255",
	red: "#213875",
	lightred: "#4f67a6",
	black: "#000",

	carouselColor: "#EEEDDE",

	fontxs: "0.75em",
	fontsm: "0.875em",
	fontmd: "1em", // 1em = 16px
	fontlg: "1.25em",
	fontxl: "2em",
	fontxxl: "3em",
	fontxxxl: "4em",

	fontButton: "0.875em",

	navHeight: "5rem",
};
