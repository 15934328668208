import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import Logo from "./Logo";
import telegram from "../assets/telegram.png";
import twitter from "../assets/twitter.png";
const Section = styled.section`
	width: 100vw;
	background-color: ${(props) => props.theme.body};
	border-bottom: 2px solid ${(props) => props.theme.red};
`;

const NavBar = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	width: 70%;
	height: ${(props) => props.theme.navHeight};
	margin: 0 auto;

	.mobile {
		display: none;
	}

	@media (max-width: 64em) {
		.desktop {
			display: none;
		}
		.mobile {
			display: inline-block;
		}
	}
`;
const Menu = styled.ul`
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;

	@media (max-width: 64em) {
		/* 1024 px */

		position: fixed;
		top: ${(props) => props.theme.navHeight};
		left: 0;
		right: 0;
		bottom: 0;
		width: 100vw;
		height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
		z-index: 50;
		background-color: ${(props) => `rgba(${props.theme.bodyRgba},0.85)`};
		backdrop-filter: blur(2px);

		transform: ${(props) => (props.click ? "translateY(0)" : `translateY(1000%)`)};
		transition: all 0.3s ease;
		flex-direction: column;
		justify-content: center;

		touch-action: none;
	}
`;

const MenuItem = styled.li`
	margin: 0 1rem;
	color: ${(props) => props.theme.blue};
	cursor: pointer;

	&::after {
		content: " ";
		display: block;
		width: 0%;
		height: 2px;
		background: ${(props) => props.theme.blue};
		transition: width 0.3s ease;
	}
	&:hover::after {
		width: 100%;
	}

	@media (max-width: 64em) {
		margin: 1rem 0;

		&::after {
			display: none;
		}
	}
`;
const HamburgerMenu = styled.span`
	width: ${(props) => (props.click ? "2rem" : "1.5rem")};

	height: 2px;
	background: ${(props) => props.theme.text};

	position: absolute;
	top: 2rem;
	left: 50%;
	transform: ${(props) => (props.click ? "translateX(-50%) rotate(90deg)" : "translateX(-50%) rotate(0)")};

	display: none;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	transition: all 0.3s ease;

	@media (max-width: 64em) {
		/* 1024 px */
		display: flex;
	}

	&::after,
	&::before {
		content: " ";
		width: ${(props) => (props.click ? "1rem" : "1.5rem")};
		height: 2px;
		right: ${(props) => (props.click ? "-2px" : "0")};
		background: ${(props) => props.theme.text};
		position: absolute;
		transition: all 0.3s ease;
	}

	&::after {
		top: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
		transform: ${(props) => (props.click ? "rotate(-40deg)" : "rotate(0)")};
	}
	&::before {
		bottom: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
		transform: ${(props) => (props.click ? "rotate(40deg)" : "rotate(0)")};
	}
`;

const IconList = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto;

	& > * {
		padding-right: 0.5rem;
		transition: all 0.2s ease;

		&:hover {
			transform: scale(1.2);
		}
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-left: -120px;

	img {
		margin-right: 10px;
	}

	@media (max-width: 64em) {
		display: none;
		margin: 0;
	}
`;

const Navigation = () => {
	const [click, setClick] = useState(false);

	const scrollTo = (id) => {
		let element = document.getElementById(id);

		element.scrollIntoView({
			behavior: "smooth",
			block: "start",
			inline: "nearest",
		});

		setClick(!click);
	};

	return (
		<Section id="navigation">
			<NavBar>
				<Logo />
				<HamburgerMenu click={+click} onClick={() => setClick(!click)}>
					&nbsp;
				</HamburgerMenu>
				<Menu click={+click}>
					<MenuItem onClick={() => scrollTo("about")}>ABOUT</MenuItem>
					<MenuItem onClick={() => scrollTo("buy")}>HOW TO BUY</MenuItem>
					{/* <MenuItem onClick={() => scrollTo("roadmap")}>ROADMAP</MenuItem>
					<MenuItem onClick={() => scrollTo("showcase")}>SHOWCASE</MenuItem>
					<MenuItem onClick={() => scrollTo("faq")}>FAQ</MenuItem> */}
				</Menu>
				<ButtonContainer>
					<IconList>
						<a href="https://t.me/trumpxbase" target="_blank" rel="noopener noreferrer" aria-label="facebook">
							<img width={40} height={40} src={telegram} alt="trump" />
						</a>
						<a href="https://x.com/BasedTrumpOnX" target="_blank" rel="noopener noreferrer" aria-label="twitter">
							<img width={40} height={40} src={twitter} alt="trump" />
						</a>
					</IconList>
					<div className="desktop">
						<Button text="BUY $TRUMPX" link="https://t.me/trumpxbase" />
					</div>
				</ButtonContainer>
			</NavBar>
		</Section>
	);
};

export default Navigation;
