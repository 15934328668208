import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useRef } from "react";
import styled from "styled-components";
import DrawSvg from "../DrawSvg";
import background from "../../assets/b3.png";
import { ThemeProvider } from "styled-components";
import { dark } from "../../styles/Themes";

const Section = styled.section`
	width: 100vw;
	background-color: ${(props) => props.theme.body};
	background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url("${background}");
	background-position: bottom right;
	background-repeat: no-repeat;
	position: relative;
	display: inline-block;
	overflow: hidden;
`;
const Title = styled.h1`
	font-size: ${(props) => props.theme.fontxxxl};
	font-weight: 700;
	text-transform: capitalize;
	color: ${(props) => props.theme.text};
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem auto;
	margin-top: 2rem;
	border-bottom: 2px solid ${(props) => props.theme.text};
	width: fit-content;

	@media (max-width: 40em) {
		font-size: ${(props) => props.theme.fontxl};
	}
`;
const Container = styled.div`
	width: 70%;
	height: 100vh;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	@media (max-width: 64em) {
		width: 80%;
	}
	@media (max-width: 48em) {
		width: 90%;
	}
`;
const SvgContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Items = styled.ul`
	list-style: none;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (max-width: 48em) {
		width: 90%;
	}

	& > *:nth-of-type(2n + 1) {
		justify-content: start;
		@media (max-width: 48em) {
			justify-content: center;
		}

		div {
			text-align: right;

			@media (max-width: 48em) {
				text-align: left;
				p {
				}
			}
		}
		p {
		}
	}
	& > *:nth-of-type(2n) {
		justify-content: end;
		@media (max-width: 48em) {
			justify-content: center;
		}
		div {
			text-align: left;
		}
		p {
		}
	}
`;
const Item = styled.li`
	width: 100%;
	height: auto;
	display: flex;

	@media (max-width: 48em) {
		justify-content: flex-end !important;
	}
`;
const ItemContainer = styled.div`
	width: 40%;
	height: fit-content;
	padding: 1rem;
	border: 3px solid ${(props) => props.theme.red};

	@media (max-width: 48em) {
		width: 70%;
	}
`;

const Box = styled.p`
	height: fit-content;
	background-color: ${(props) => props.theme.lightred};
	color: ${(props) => props.theme.text};
	padding: 1rem;
	position: relative;
	border: 1px solid ${(props) => props.theme.text};
`;
const SubTitle = styled.span`
	display: block;
	font-weight: 500;
	font-size: ${(props) => props.theme.fontxl};
	text-transform: capitalize;
	color: ${(props) => props.theme.black};

	@media (max-width: 40em) {
		font-size: ${(props) => props.theme.fontlg};
		font-weight: 600;
	}
`;
const Text = styled.span`
	display: block;
	font-size: ${(props) => props.theme.fontsm};
	text-transform: capitalize;
	color: ${(props) => props.theme.black};

	font-weight: 400;
	margin: 0.5rem 0;
	@media (max-width: 40em) {
		font-size: ${(props) => props.theme.fontxs};
	}
`;

const RoadMapItem = ({ title, subtext, addToRef }) => {
	return (
		<Item ref={addToRef}>
			<ItemContainer>
				<Box>
					<SubTitle>{title} </SubTitle>
					<Text>{subtext}</Text>
				</Box>
			</ItemContainer>
		</Item>
	);
};

const Roadmap = () => {
	const revealRefs = useRef([]);
	revealRefs.current = [];
	gsap.registerPlugin(ScrollTrigger);

	const addToRefs = (el) => {
		if (el && !revealRefs.current.includes(el)) {
			revealRefs.current.push(el);
		}
	};

	return (
		<Section id="roadmap">
			<Title>ROADMAP</Title>
			<Container>
				<SvgContainer>
					<DrawSvg />
				</SvgContainer>
				<Items>
					<Item>&nbsp;</Item>
					<RoadMapItem addToRef={addToRefs} title="Grand Opening" subtext="Come for the policies, stay for the bag. Free MAGA hats for the first 100 attendees!" />

					<ThemeProvider theme={dark}>
						<RoadMapItem addToRef={addToRefs} title="Start a Cult" subtext="Join the Trump Tribe today! Secret handshakes, no Jeeters and Twitter wars included." />
					</ThemeProvider>

					<RoadMapItem addToRef={addToRefs} title="Build a Wall" subtext="Because every good campaign needs a wall. Made with 100% American memes." />

					<ThemeProvider theme={dark}>
						<RoadMapItem addToRef={addToRefs} title="Deport the Refugees" subtext="Making America gated again, one deportation at a time." />
					</ThemeProvider>
				</Items>
			</Container>
		</Section>
	);
};

export default Roadmap;
