import React, { lazy, Suspense } from "react";
import styled, { keyframes } from "styled-components";
// import CoverVideo from '../CoverVideo'
// import TypeWriterText from '../TypeWriterText'
import RoundTextBlack from "../../assets/Rounded-Text-White.png";
import Loading from "../Loading";
import background from "../../assets/hero-background.png";

const CoverVideo = lazy(() => import("../CoverVideo"));
const TypeWriterText = lazy(() => import("../TypeWriterText"));

const Section = styled.section`
	min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
	width: 100vw;
	position: relative;
	background-color: lightblue;
	background-image: url("${background}");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@media (max-width: 48em) {
		background-position: 30% 100%; /* Offset from the middle to the bottom */
	}
`;

const IconList = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem auto;

	& > * {
		padding-right: 0.5rem;
		transition: all 0.2s ease;

		&:hover {
			transform: scale(1.2);
		}
	}
`;

const Container = styled.div`
	width: 75%;
	min-height: 80vh;
	margin: 0 auto;

	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 64em) {
		width: 85%;
	}
	@media (max-width: 48em) {
		flex-direction: column-reverse;
		width: 100%;
		& > *:first-child {
			width: 100%;
			margin-top: 2rem;
		}
	}
`;
const Box = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const rotate = keyframes`
100%{
  transform: rotate(1turn);
}
`;
const Round = styled.div`
	position: absolute;
	bottom: 3.5rem;
	right: 3.5rem;
	width: 6rem;
	height: 6rem;
	border: 1px solid ${(props) => props.theme.body};
	border-radius: 50%;

	img {
		width: 100%;
		height: auto;
		animation: ${rotate} 6s linear infinite reverse;
	}
	@media (max-width: 64em) {
		width: 4rem;
		height: 4rem;
		left: none;
		right: 2rem;
		bottom: 100%;
		display: none;
	}
	@media (max-width: 48em) {
		right: 1rem;
	}
`;

const Circle = styled.span`
	width: 3rem;
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	background-color: ${(props) => props.theme.body};
	color: ${(props) => props.theme.text};
	font-size: ${(props) => props.theme.fontxl};

	@media (max-width: 64em) {
		width: 2rem;
		height: 2rem;
		font-size: ${(props) => props.theme.fontlg};
	}
`;

const Home = () => {
	return (
		<Section id="home">
			<Container>
				{/* <Box>
					<Suspense fallback={<Loading />}>
						<CoverVideo />
					</Suspense>
				</Box> */}
				<Box>
					<Suspense fallback={<Loading />}>
						<TypeWriterText />
					</Suspense>
				</Box>

				<Round>
					<Circle>&#x2193;</Circle>
					<img width={500} height={400} src={RoundTextBlack} alt="text" />
				</Round>
			</Container>
		</Section>
	);
};

export default Home;
