import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logoSrc from "../assets/logo.png";

const LogoText = styled.h1`
	font-family: "Montserrat", sans-serif;
	font-size: ${(props) => props.theme.fontxxxl};
	color: ${(props) => props.theme.text};
	transition: all 0.2s ease;
	img {
		width: auto;
		height: 3rem;
	}

	&:hover {
		transform: scale(1.1);
	}

	@media (max-width: 64em) {
		font-size: ${(props) => props.theme.fontxxl};
		display: none;
	}
`;

const Logo = () => {
	return (
		<LogoText>
			<Link to="/">
				<img src={logoSrc} alt="Logo" />
			</Link>
		</LogoText>
	);
};

export default Logo;
