import styled from "styled-components";
// import Carousel from '../Carousel'
import Loading from "../Loading";
import background from "../../assets/bg2.png";

import React, { lazy, Suspense } from "react";
const ConfettiComponent = lazy(() => import("../Confetti"));
const Carousel = lazy(() => import("../Carousel"));

const Section = styled.section`
	min-height: 100vh;
	width: 100%;
	background-color: ${(props) => props.theme.lightblue};
	background-image: linear-gradient(rgb(79, 103, 166, 0.97), rgb(79, 103, 166, 0.97)), url("${background}");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
`;
const Container = styled.div`
	width: 75%;
	margin: 0 auto;
	/* background-color: lightblue; */

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@media (max-width: 70em) {
		width: 85%;
	}

	@media (max-width: 64em) {
		width: 100%;
		flex-direction: column;

		& > *:last-child {
			width: 80%;
		}
	}
	@media (max-width: 40em) {
		& > *:last-child {
			width: 90%;
		}
	}
`;
// const Box = styled.div`
// 	width: 50%;
// 	height: 100%;
// 	min-height: 60vh;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;

// 	@media (max-width: 40em) {
// 		min-height: 50vh;
// 	}
// `;

const Title = styled.h2`
	font-size: ${(props) => props.theme.fontxxxl};
	text-transform: capitalize;
	font-weight: 700;
	color: ${(props) => props.theme.body};
	width: 100%;
	text-align: center;

	@media (max-width: 64em) {
		width: 100%;
		text-align: center;
	}
	@media (max-width: 40em) {
		font-size: ${(props) => props.theme.fontxl};
	}
	@media (max-width: 30em) {
		font-size: ${(props) => props.theme.fontlg};
	}
`;
const SubText = styled.p`
	font-size: ${(props) => props.theme.fontlg};
	color: ${(props) => props.theme.body};
	width: 100%;
	text-align: center;
	margin: 1rem auto;
	margin-bottom: 3rem;
	font-weight: 400;
	@media (max-width: 64em) {
		width: 100%;
		text-align: center;
		font-size: ${(props) => props.theme.fontmd};
	}
	@media (max-width: 40em) {
		font-size: ${(props) => props.theme.fontmd};
	}
	@media (max-width: 30em) {
		font-size: ${(props) => props.theme.fontsm};
	}
`;
// const SubTextLight = styled.p`
// 	font-size: ${(props) => props.theme.fontmd};
// 	color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
// 	align-self: flex-start;
// 	width: 80%;
// 	margin: 1rem auto;
// 	font-weight: 400;

// 	@media (max-width: 64em) {
// 		width: 100%;
// 		text-align: center;
// 		font-size: ${(props) => props.theme.fontsm};
// 	}
// 	@media (max-width: 40em) {
// 		font-size: ${(props) => props.theme.fontsm};
// 	}
// 	@media (max-width: 30em) {
// 		font-size: ${(props) => props.theme.fontxs};
// 	}
// `;
// const ButtonContainer = styled.div`
// 	width: 80%;
// 	margin: 10rem auto;
// 	display: flex;
// 	justify-content: flex-start;

// 	@media (max-width: 64em) {
// 		width: 100%;
// 		justify-content: center;

// 		button {
// 			padding-right: 1rem;
// 			margin: 0 auto;
// 		}
// 	}
// `;

const About = () => {
	return (
		<Section id="buy">
			<Suspense fallback={<Loading />}>
				<ConfettiComponent />{" "}
			</Suspense>
			<Container>
				<Title>HOW TO BUY</Title>
				<SubText>Do not miss out on the greatest American purchase</SubText>
				<Suspense fallback={<Loading />}>
					<Carousel />{" "}
				</Suspense>{" "}
			</Container>
		</Section>
	);
};

export default About;
