import React, { useRef } from "react";
import styled, { keyframes } from "styled-components";
import background from "../../assets/bg2.png";

import img1 from "../../assets/showcase/img1.png";
import img2 from "../../assets/showcase/img2.png";
import img3 from "../../assets/showcase/img3.png";
import img4 from "../../assets/showcase/img4.png";
import img5 from "../../assets/showcase/img5.png";
import img6 from "../../assets/showcase/img6.png";
import img7 from "../../assets/showcase/img7.png";
import img8 from "../../assets/showcase/img8.png";
import img9 from "../../assets/showcase/img9.png";
import img10 from "../../assets/showcase/img10.png";

const Section = styled.section`
	min-height: 100vh;
	width: 100vw;
	background-color: ${(props) => props.theme.lightred};
	background-image: linear-gradient(rgb(255, 79, 90, 0.95), rgb(255, 79, 90, 0.95)), url("${background}");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	& > *:first-child {
		animation-duration: 20s;

		@media (max-width: 30em) {
			animation-duration: 15s;
		}
	}
	& > *:last-child {
		animation-duration: 15s;
		@media (max-width: 30em) {
			animation-duration: 10s;
		}
	}
`;
const move = keyframes`
0%{ transform: translateX(100%)   };
100%{ transform: translateX(-100%)   }

`;

const Row = styled.div`
	/* background-color: lightblue; */
	white-space: nowrap;
	box-sizing: content-box;
	margin: 2rem 0;
	display: flex;

	animation: ${move} linear infinite ${(props) => props.direction};
`;
const ImgContainer = styled.div`
	width: 15rem;
	height: 20rem;
	margin: 0 1rem;
	border: 3px solid ${(props) => props.theme.blue};
	background-color: ${(props) => props.theme.body};

	cursor: pointer;

	@media (max-width: 48em) {
		width: 12rem;
	}
	@media (max-width: 30em) {
		width: 10rem;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
`;

// const Details = styled.div`
// 	display: flex;
// 	justify-content: space-between;
// 	padding: 0.8rem 1rem;
// 	background-color: ${(props) => props.theme.text};
// 	border: 2px solid ${(props) => `rgba(${props.theme.bodyRgba},0.5)`};

// 	span {
// 		font-size: ${(props) => props.theme.fontsm};
// 		color: ${(props) => `rgba(${props.theme.bodyRgba},0.5)`};
// 		font-weight: 600;
// 		line-height: 1.5rem;
// 	}

// 	h1 {
// 		font-size: ${(props) => props.theme.fontmd};
// 		color: ${(props) => props.theme.body};
// 		font-weight: 600;

// 		@media (max-width: 30em) {
// 			font-size: ${(props) => props.theme.fontsm};
// 		}
// 	}
// `;

// const Price = styled.div`
// 	display: flex;
// 	justify-content: flex-start;
// 	align-items: center;

// 	img {
// 		width: 1rem;
// 		height: auto;
// 	}
// `;

const NftItem = ({ img, number = 0, price = 0, passRef }) => {
	let play = (e) => {
		passRef.current.style.animationPlayState = "running";
	};
	let pause = (e) => {
		passRef.current.style.animationPlayState = "paused";
	};

	return (
		<ImgContainer onMouseOver={(e) => pause(e)} onMouseOut={(e) => play(e)}>
			<img width={500} height={400} src={img} alt="Trump" />
			{/* <Details>
				<div>
					<span>Trump</span> <br />
					<h1>#{number}</h1>
				</div>

				<div>
					<span>Price</span>
					<Price>
						<img width={200} height={200} src={ETH} alt="ETH" />
						<h1>{Number(price).toFixed(1)}</h1>
					</Price>
				</div>
			</Details> */}
		</ImgContainer>
	);
};

const Showcase = () => {
	const Row1Ref = useRef(null);
	const Row2Ref = useRef(null);

	return (
		<Section id="showcase">
			<Row direction="none" ref={Row1Ref}>
				<NftItem img={img1} number={852} price={1} passRef={Row1Ref} />
				<NftItem img={img2} number={123} price={1.2} passRef={Row1Ref} />
				<NftItem img={img3} number={456} price={2.5} passRef={Row1Ref} />
				<NftItem img={img4} number={666} price={3.5} passRef={Row1Ref} />
				<NftItem img={img5} number={452} price={4.7} passRef={Row1Ref} />
			</Row>
			<Row direction="reverse" ref={Row2Ref}>
				<NftItem img={img6} number={888} price={1.2} passRef={Row2Ref} />
				<NftItem img={img7} number={211} price={3.2} passRef={Row2Ref} />
				<NftItem img={img8} number={455} price={1.8} passRef={Row2Ref} />
				<NftItem img={img9} number={456} price={5.1} passRef={Row2Ref} />
				<NftItem img={img10} number={865} price={3.7} passRef={Row2Ref} />
			</Row>
		</Section>
	);
};

export default Showcase;
