import styled, { ThemeProvider } from "styled-components";
// import Carousel from '../Carousel'
import Button from "../Button";
import { dark } from "../../styles/Themes";
import hero from "../../assets/mugshot.png";
import background from "../../assets/bg1.png";

const Section = styled.section`
	min-height: 100vh;
	width: 100%;
	background-color: ${(props) => props.theme.body};
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	background-image: linear-gradient(rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), url("${background}");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;
const Container = styled.div`
	width: 75%;
	margin: 0 auto;
	/* background-color: lightblue; */

	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 70em) {
		width: 85%;
	}

	@media (max-width: 64em) {
		width: 100%;
		flex-direction: column;

		& > *:last-child {
			width: 80%;
		}
	}
	@media (max-width: 40em) {
		& > *:last-child {
			width: 90%;
		}
	}
`;
const Box = styled.div`
	width: 50%;
	height: 100%;
	min-height: 60vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;

	img {
		width: 700px;
		height: auto;
	}

	@media (max-width: 64em) {
		min-height: 20vh;
		justify-content: center;
		align-items: center;
		img {
			margin-bottom: 20px;
			width: 500px;
			height: auto;
		}
	}

	@media (max-width: 40em) {
		min-height: 20vh;
		justify-content: center;
		align-items: center;
		img {
			margin-bottom: 20px;
			width: 300px;
			height: auto;
		}
	}
`;

const Title = styled.h2`
	font-size: ${(props) => props.theme.fontxxxl};
	text-transform: capitalize;
	color: ${(props) => props.theme.blue};
	align-self: flex-start;
	width: 80%;
	margin: 0 auto;

	@media (max-width: 64em) {
		width: 100%;
		text-align: center;
	}
	@media (max-width: 40em) {
		font-size: ${(props) => props.theme.fontxl};
	}
	@media (max-width: 30em) {
		font-size: ${(props) => props.theme.fontlg};
	}
`;
const SubText = styled.p`
	font-size: ${(props) => props.theme.fontlg};
	color: ${(props) => props.theme.text};
	align-self: flex-start;
	width: 80%;
	margin: 1rem auto;
	font-weight: 400;
	@media (max-width: 64em) {
		width: 100%;
		text-align: center;
		font-size: ${(props) => props.theme.fontmd};
	}
	@media (max-width: 40em) {
		font-size: ${(props) => props.theme.fontmd};
	}
	@media (max-width: 30em) {
		font-size: ${(props) => props.theme.fontsm};
	}
`;
// const SubTextLight = styled.p`
// 	font-size: ${(props) => props.theme.fontmd};
// 	color: ${(props) => props.theme.text};
// 	align-self: flex-start;
// 	width: 80%;
// 	margin: 1rem auto;
// 	font-weight: 400;

// 	@media (max-width: 64em) {
// 		width: 100%;
// 		text-align: center;
// 		font-size: ${(props) => props.theme.fontsm};
// 	}
// 	@media (max-width: 40em) {
// 		font-size: ${(props) => props.theme.fontsm};
// 	}
// 	@media (max-width: 30em) {
// 		font-size: ${(props) => props.theme.fontxs};
// 	}
// `;
const ButtonContainer = styled.div`
	width: 80%;
	margin: 1rem auto;
	display: flex;
	justify-content: flex-start;
	gap: 1rem;

	@media (max-width: 64em) {
		width: 100%;
		justify-content: center;

		button {
			margin: 0 auto;
		}
	}
`;

const Buy = () => {
	return (
		<Section id="about">
			<Container>
				<Box>
					<img src={hero} alt="hero" />
				</Box>
				<Box>
					<Title>Hey X...</Title>
					<Title>
						<b>DADDYS HOME!</b>
					</Title>
					<SubText>
						Guess who's back, back again—Trump's tweeting fingers are itching, and he's got 2024 in his sights like it's his next Mar-a-Lago! After a hiatus that felt
						like a Shawshank sequel, he's back on X, ready to make his return bigger than ever. Armed with a fresh tan, a swagger that says "I never left," and more
						tweets than you can count, he's not just aiming for the Oval Office—he's ready to build a gold-plated, tweet-proof fortress around it. Buckle up,
						America—Trump's back online, and he's ready to make the internet great again
					</SubText>
					{/* <SubTextLight>
						With more than 200+ hand drawn traits, each NFT is unique and comes with a membership to an exclusive group of successful investors. Join an ambitious
						ever-growing community with multiple benefits and utilities.
					</SubTextLight> */}
					<ButtonContainer>
						<Button text="JOIN OUR TELEGRAM" link="https://t.me/trumpxbase" newTab={true} />
						<ThemeProvider theme={dark}>
							<Button text="JOIN OUR TWITTER" link="https://x.com/BasedTrumpOnX" newTab={true} />
						</ThemeProvider>
					</ButtonContainer>
				</Box>
			</Container>
		</Section>
	);
};

export default Buy;
